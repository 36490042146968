.weight button {
  height: 36px;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 12px;
}

.weightText {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #888;
}

.weightInputWrap {
  display: flex;

  input {
    &::placeholder {
      color: #30223B
    }
  }

  input:first-of-type {
    text-align: right;
  }

  input:nth-of-type(2) {
    text-align: left;
  }

  span {
    display: block;
    padding: 0 5px;
    width: auto;
    font-size: 75px;
    line-height: 100px;
    font-weight: 600;
    color: #30223B;
  }

}