.requisites {
  background-color: #D9D1E0;
  min-height: 100vh;
  padding: 0 16px 100px;

  h2 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: #201827;
    text-align: center;
    padding-top: 20px;
  }

  h3 {
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    font-weight: 600;
    color: #201827;
  }
}

.content {
  margin-top: 14px;
  background-color: #F1EEF3;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 10px 15px 20px;

  .warning {
    position: relative;
    font-size: 12px;
    line-height: 16px;
    color: #DC6666;
    padding-left: 20px;
    margin-left: 12px;
    margin-top: 5px;
    margin-bottom: 10px;

    &:before {
      position: absolute;
      content:'';
      left:0;
      top:50%;
      width: 15px;
      height: 15px;
      transform: translate(0,-50%);
      background: url('../../../assets/img/warning.svg') no-repeat left top;
    }
  }

  &__title {
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    color: #888888;
    padding-left: 12px;
    display: block;
    margin-bottom: 5px;
  }

  &__field {
    background-color: #FFFFFF;
    border-radius: 4px;
    height: 36px;
    padding: 0 10px 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__leftField {
    span {
      color: #201827;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }
}