.deliveryNP {
  background-color: #F6D6D7;
  width: 100vw;
  min-height: 100vh;
  max-width: 768px;
  padding: 10px 16px;

  &__title {
    margin: 20px auto 12px;
    color: #201827;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
  }

  &__content {
    background-color: #F0B5B7;
    padding: 14px 15px 20px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  &__row {
    display: grid;
    column-gap: 16px;
    grid-template-columns: 1fr 1fr;
  }

  &__select {
    position: relative;
  }

  input, textarea {
    width: 100%;
  }

  textarea {
    padding: 12px;
    max-height: 133px;
  }
}

.transportNumber {
  position: relative;
}

.copyButton {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);

  img {
    width: 15px;
    height: 17px;
  }
}

.closedInput {
  input {
    background-color: #D9D1E0;

    &::placeholder {
      color: #6F6279;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }
}
