.order {
  background-color: #D9D1E0;
  width: 100vw;
  min-height: 100vh;
  max-width: 768px;
  padding: 10px 16px;

  &__title {
    margin: 20px auto 12px;
    color: #201827;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
  }

  &__content {
    background-color: #F1EEF3;
    padding: 14px 15px 20px;
    border-radius: 4px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
      color: #888888;
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  input {
    width: 100%;
  }

  textarea {
    height: 130px;
    padding: 10px 12px;
    width: 100%;

    &::placeholder {
      color: #888;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  &__userUrl {
    position: relative;

    .pasteBtn {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);

      &:hover {
        img {
          filter: brightness(0.5);
        }
      }
    }
  }

  &__name {
    display: flex;
    column-gap: 16px;
  }
}

.payChanger {
  height: 36px;
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 26px;
  padding: 5px;

  span {
    color: #888;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    padding-left: 12px;
    white-space: nowrap;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    column-gap: 5px;
    height: 100%;
    width: 100%;

    div {
      width: 100%;
    }
  }
}

.statusPay {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  padding: 10px 8px 10px 12px;

  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #888;
  }
}

.discountField {
  border-radius: 4px;
  border: 2px solid #ED1C24;
  height: 38px;
  padding: 3px 3px 3px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 26px;

  &__wrapper {
    display: flex;
    align-items: center;
    column-gap: 5px;
    height: 100%;
    width: 100%;

    div {
      width: 100%;
    }

    p {
      padding-top: 2px;
    }
  }

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #201827;
    white-space: nowrap;
  }

  .percentInputWrap, .UAHInputWrap {
    display: flex;

    span {
      display: block;
      padding: 0;
      width: 100%;
      font-size: 75px;
      line-height: 100px;
      font-weight: 600;
      color: #30223B;
    }

    input {
      text-align: end;
    }
  }

  .UAHInputWrap {
    span {
      width: 150px;
      font-size: 35px;
    }
  }
}

.closedInput {
  input {
    background-color: #D9D1E0;

    &::placeholder {
      color: #6F6279;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }
}

.urgentlyField {
  height: 36px;
  border-radius: 4px;
  background-color: #fff;
  padding: 10px 12px;
}

.customCheckbox {
  display: inline-flex;
  align-items: center;
  column-gap: 15px;
  position: relative;

  input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
  }

  span {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-color: #DDDFE3;
    pointer-events: none;
    transition: all .15s;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #888888;
  }
}

.customCheckbox:has(input:checked) {
  span {
    background-color: #573971;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      left: 50%;
      top: 45%;
      transform: translateX(-50%) translateY(-50%) rotateZ(-45deg);
      width: 10px;
      height: 6px;
      border-bottom: 2px solid #fff;
      border-left: 2px solid #fff;
    }
  }
}

.action {
  margin-top: 10px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}