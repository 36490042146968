.type {
  padding: 10px 15px;
  margin-bottom: 20px;
  background: #F1EEF3;
  border-radius: 4px;

  h3 {
    font-size: 10px;
    line-height: 12px;
    color: #201827;
    text-align: center;
  }
}