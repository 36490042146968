.status {
  background: #D9D1E0;
  min-height: 100vh;
  padding: 0 16px 100px;

  &__logo {
    text-align: center;
    padding: 20px 0;
  }

  h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #201827;
  }

  h3 {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: #201827;
    margin-bottom: 5px;
  }

  &__date {
    font-size: 10px;
    line-height: 12px;
    color: #636363;
    text-align: center;
    padding-bottom: 13.5px;
  }
}

.notFound {
  min-height: 100svh;
  padding: 100px 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #D9D1E0;
  
  .message {
      font-size: 20px;
      font-weight: 600;
      line-height: 36px;
      text-align: center;
      margin-bottom: 20px;
  }
}