.shadowActive, .shadowHide {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  background-color: transparent;
  z-index: 9;
  height: 100vh;
  width: 100vw;
  transition: all .15s;
}

.shadowActive {
  pointer-events: all;
  background-color: rgba(48, 34, 59, 0.5);
}