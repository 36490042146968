.fabric {
  background-color: #D9D1E0;
  width: 100vw;
  min-height: 100vh;
  max-width: 768px;
  padding: 26px 16px;
}

.imgPlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 220px;
  border: 1px solid #573971;
  border-radius: 4px;
  background: #ffffff;
  margin-bottom: 20px;

  img {
    display: block;
  }

  .mainImg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.tableWrap {
  padding: 15px;
  background: #F1EEF3;
  border-radius: 4px;
  margin-bottom: 10px;

  h3 {
    color: #201827;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 10px;
  }
}

.withSlider {
  margin-top: 110px;
}

.table {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
  margin-bottom: 10px;

  div {
    background: #FFFFFF;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    font-size: 12px;
    line-height: 12px;
    color: #636363;

    &:nth-child(3n+2) {
      font-weight: 600;
      color: #201827;
    }

    &:nth-child(-n+3) {
      padding: 0;
      background: #F1EEF3;
    }
  }

  & + p {
    font-size: 12px;
    line-height: 22px;
    color: #201827;
    text-align: center;
  }
}