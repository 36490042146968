.order {
  padding: 10px;
  background-color: #F1EEF3;
  border-radius: 4px;
  position: relative;
  min-height: 210px;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    span {
      color: #636363;
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
    }
  }

  &__footerWrapper {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    width: 100%;
  }

  &__footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 18px;
      font-weight: 600;
      color: #201827;
    }
  }
}

.content {
  display: flex;
  column-gap: 6px;

  &__boxes {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    width: 100%;
  }

  &__count {
    padding: 10px;
    background-color: #fff;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    img {
      width: 23px;
      height: 23px;
    }

    p {
      color: #636363;
      white-space: nowrap;
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
    }
  }
}

.box {
  height: 100%;
  background-color: #fff;
  border-radius: 2px;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  min-height: 58px;

  &__text {
    color: #636363;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
  }

  &__phone {
    color: #201827;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
  }

  &__role {
    position: absolute;
    right: 6px;
    color: #636363;
    top: 4px;
    font-size: 7px;
    font-weight: 400;
    line-height: 12px;
  }
}

.menuBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  position: relative;
  z-index: 2;
}

.menu {
  padding: 16px 50px 12px 10px;
  background-color: #F1EEF3;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 3;

  &__actions {
    display: flex;
    flex-direction: column;
    row-gap: 6px;

    button {
      background-color: #fff;
      width: 283px;
      height: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 12px;
    }
  }

  &__copy img {
    width: 15px;
    height: 17px;
  }

  &__remove {
    background-color: #F0B5B7 !important;
  }

  &__close {
    position: absolute;
    bottom: 12px;
    right: 12px;
  }
}

.backgroundClose {
  z-index: 1;
  opacity: 0;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.globalPopup, .globalPopupActive {
  position: fixed;
  //left: 0;
  bottom: -200vh;
  height: auto;
  width: 100vw;
  background-color: #F1EEF3;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  z-index: 10;
  transition: all .15s;
  max-width: 768px;
  left: 50%;
  transform: translateX(-50%);

  &__content {
    padding: 30px;
  }
}

.globalPopupActive {
  bottom: 0;
}

.topPopup {
  bottom: auto;
  top: 23px;
  height: 100vh;
}

.header {
  height: 48px;
  background-color: #D9D1E0;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  &__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: #201827;
  }

  &__close {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
}

.shadowActive, .shadowHide {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  background-color: transparent;
  z-index: 9;
  height: 100vh;
  width: 100vw;
  transition: all .15s;
}

.shadowActive {
  pointer-events: all;
  background-color: rgba(48, 34, 59, 0.5);
}