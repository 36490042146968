.paymentBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 37px;
  padding: 0;
  border: none;
  background: #F1EEF3;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;

  img {
    height: 20px;
  }
}