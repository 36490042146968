.fabrics {
  background-color: #D9D1E0;
  width: 100vw;
  min-height: 100vh;
  max-width: 768px;
  padding: 10px 16px;
  position: relative;

  .warning {
    padding-top: 20px;
  }
}
