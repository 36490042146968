.products {
  background-color: #D9D1E0;
  width: 100vw;
  min-height: 100vh;
  max-width: 768px;
  padding: 10px 16px;

  &__title {
    margin: 20px auto 12px;
    color: #201827;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
  }
}