.textareaBlock {
  margin: 0 -15px 10px -15px;

  .textarea {
    width: 100%;
    height: 264px;
    padding: 20px 15px 30px;
    background-color: #ffffff;
    font-size: 12px;
    line-height: 22px;
    color: #5C5C5C;
    border-radius: 4px;
  }
}